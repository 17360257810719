
interface CheckingRoundType {
  key: string;
  value: string;
  text: string;
}
interface ManagerType {
  name: string;
  id: string;
}
import { ref, onMounted, reactive, toRefs, defineComponent } from "vue";
import { FileItem } from "@/API/types";
import { message } from "ant-design-vue";
import { getManagerApi } from "@/API/claim/delaerClaim/delaer";
import {
  uploadSourceData,
  addSourceData,
} from "@/API/checking/SICCOCheckingDetail";
import { uploadCallback } from "@/API/claim/claimQuery";

import axios from "axios";
export default defineComponent({
  emits: ["fresh-table"],
  setup(prop, ctx) {
    const eligiblePeriodStr = ref("");
    const programCode = ref("");
    const formData = new FormData();
    const visible = ref(false);
    const isBeforeUpload = ref<boolean>(true);
    const claimAppId = ref("");

    const showError = ref(false);

    //区域经理数组
    const managerArray = ref<ManagerType[]>([]);

    // 进度条
    const isShowUploadBtn = ref<boolean>(true);
    const isClickAbled = ref<boolean>(true);

    const curUploadFile = ref<object>({});
    const curPercent = ref<number>(0);
    const uploadFileSize = ref<string>("");
    const completeTips = ref<boolean>(false);

    const manager = ref<string | null>(null);
    const errorText = ref("");

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //显示抽屉
    const showDrawer = (item: any) => {
      console.log(item);
      eligiblePeriodStr.value = item.eligiblePeriodStr;
      programCode.value = item.programCode;
      claimAppId.value = item.id;

      getManagerApi({
        dealerCode: item.dealerCode,
        programBu: item.bu,
      }).then((res: any) => {
        console.log(res);

        const array = [];
        for (let i = 0; i < res.length; i++) {
          array.push({
            name: res[i].usrnm,
            id: res[i].usrid,
          });
        }
        managerArray.value = array;
        visible.value = true;
      });
    };

    //关闭抽屉的回调
    const afterVisibleChange = () => {
      curPercent.value = 0;
      uploadFileSize.value = "";
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      manager.value = null;
      if (formData.has("document")) {
        formData.delete("document");
      }
    };
    const loading = ref<boolean>(false);

    // 上传前
    const beforeUpload = (file: FileItem) => {
      const isXlsx = file.type === "application/pdf";
      if (!isXlsx) {
        message.error("只能上传PDF文件");
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error("文件大小不能超过10MB!");
      }
      return isXlsx && isLt5M;
    };

    const uploadRequest = (source: any) => {
      if (formData.has("document")) {
        formData.delete("document");
      }
      formData.append("document", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      isBeforeUpload.value = false;
    };

    // 上传文件
    const uploadFile = (source: any) => {
      isClickAbled.value = false;
      showError.value = false;
      if (
        manager.value === null ||
        manager.value === "" ||
        manager.value.length === 0
      ) {
        errorText.value = "请选择区域经理";
        showError.value = true;
        return false;
      }

      if (!formData.has("document")) {
        errorText.value = "请上传延期撤回证明材料";
        showError.value = true;
        return false;
      }
      const name = managerArray.value.find((item: ManagerType) => {
        return item.id === manager.value;
      })?.name;

      const parma = {
        claimAppId: claimAppId.value,
        regionName: name,
        regionCode: manager.value,
      };

      uploadCallback(formData, parma).then((res: any) => {
        console.log(res);
        isShowUploadBtn.value = false;
        curPercent.value = 100;
        completeTips.value = true;
        isClickAbled.value = true;
        if (res.code === "0") {
          message.success("延期撤回申请已提交");
          visible.value = false;
          ctx.emit("fresh-table");
        } else {
          message.error(res.message);
        }
      });
      // uploadSourceData(formData).then((res: any) => {
      //     console.log(res)
      //     isShowUploadBtn.value = false
      //     curPercent.value = 100
      //     completeTips.value = true
      //     isClickAbled.value = true
      //     message.success('Successfully uploaded')
      // }).catch((error) =>{
      //     console.log(error)
      //     isShowUploadBtn.value = false
      // })
    };

    //ok按钮，刷新表格
    return {
      uploadFile,
      loading,
      manager,
      errorText,
      showError,
      isShowUploadBtn,
      curUploadFile,
      curPercent,
      uploadFileSize,
      completeTips,
      isClickAbled,
      isBeforeUpload,
      beforeUpload,
      uploadRequest,
      eligiblePeriodStr,
      programCode,
      visible,
      afterVisibleChange,
      managerArray,
      closeThisDrawer,
      showDrawer,
    };
  },
});
